@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?kexani');
  src:  url('fonts/icomoon.eot?kexani#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?kexani') format('truetype'),
    url('fonts/icomoon.woff?kexani') format('woff'),
    url('fonts/icomoon.svg?kexani#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-arrow{
  transform-origin: 50% 50%;
  display: inline-block;

  &:before {
    content: "\e900";
  }

  &.top {
    transform: rotate(180deg);
    transform-origin: 35% 55%;
  }

  &.left {
    transform: rotate(90deg);
  }

  &.right {
    transform: rotate(-90deg) translateX(-4px);
  }
}
