.top-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
    opacity: 0;

    color: black;
    // font-weight: bold;
    font-size: 24px;
    padding: 10px;

    border: 1px solid rgba(0, 0, 0, 0.08);
    background: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    // box-shadow: 0 0 1px white;

    @media only screen and (max-width: 767px) {
        color: black;
        right: 0px;
        border-radius: 0;
        border: 1px solid rgba(0, 0, 0, 0.8);

    }

    &.show {
        opacity: 1;

        &:hover {
            color: white;
            background: black;
            font-weight: bold;
        }
    }


}

body.project-detail-opened .top-button {
    display: none;
}