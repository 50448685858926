// length variables
    $line-vertical-margin: 180;
    $detail-margin-top: 130px;
    $key-visual-width: 680px;
    $key-visual-height: 381px;
    $key-visual-width-detail: 800px;
    $key-visual-height-detail: 438px;
    $line-distance-horizontal: 687px;
    $line-distance-vertical: 389px;

    $hd-line-vertical-margin: 100;
    $hd-key-visual-width: 800px;
    $hd-key-visual-height: 508px;
    $hd-key-visual-width-detail: 1200px;
    $hd-key-visual-height-detail: 657px;
    $hd-line-distance-horizontal: 807px;
    $hd-line-distance-vertical: 516px;

    $tablet-line-vertical-margin: 145;
    $tablet-key-visual-width: 595px;
    $tablet-key-visual-height: 333px;
    $tablet-key-visual-width-detail: 700px;
    $tablet-key-visual-height-detail: 385px;
    $tablet-line-distance-horizontal: 602px;
    $tablet-line-distance-vertical: 340px;

// styles
#projects {

    article {
        position: relative;
    }

    .key-visual-container * {
        overflow: hidden;
    }

/// * animation * ///
    // background color
    article {
        transition: background 1200ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
    }

    // lines
    .lines {
        span.vertical {
            transition: height 0.3s 0.4s, left 0.4s;
            &.right {
                transition: height 0.4s, left 0.4s;
            }
        }
        span.horizontal {
            transition: width 0.4s, top 0.3s 0.4s;
            &.bottom {
                transition: width 0.4s, top 0.4s;
            }
        }
    }

    // key visual
    .key-visual-container {
        transition: left 0.4s;
    }

    .key-visual {
        transition: margin-top 0.3s 0.4s, width 0.4s, height 0.3s;
        
        .key-visual-img-container {
            transition: transform 0.4s;
        }

        img {
            transition: transform 0.6s 0.4s;
        }

        .img-shadow {
            // transition: width 0.3s, height 0.3s;

            &:after {
                transition: opacity 0.3s 0.4s; 
            }
        }
    }

    // title
    h2 {
        transition: opacity 0.4s ease-in;

        &.detail-title {
            transition: opacity 0.3s ease-in 0.2s;
        }
    }

    // info
    .info {
        &.more-info, 
        &.less-info {
            transition: opacity 1s 0.6s;
        }
    }

    // odd even
    article:nth-child(even) {
        .lines {
            span.vertical {
                transition: height 0.4s, right 0.4s;
                &.right {
                    transition: height 0.3s 0.4s, right 0.4s;
                }
            }
            span.horizontal {
                transition: width 0.4s, top 0.3s 0.4s;
                &.bottom {
                    transition: width 0.4s, top 0.4s;
                }
            }
        }

        // key visual
        .key-visual-container {
            transition: right 0.4s;
        }

        .key-visual {
            transition: margin-top 0.3s 0.4s, margin-left 0.4s, width 0.4s, height 0.3s;

            .key-visual-img-container {
                transition: transform 0.4s;
            }

            img {
                transition: transform 0.6s 0.4s, margin-left 0.4s;
            }
        }
    }

/// -------- overview ---------- ///
    .detail-only {
        opacity: 0;
        visibility: hidden;
        display: none;
    }
//// overview: key visual
    .key-visual-container {
        overflow: visible;
        position: relative;
    }

    .key-visual {
        position: relative;
        overflow: hidden;

        .key-visual-img-container {
            transform: perspective(850px) 
                translate3d(0px, -15px, -50px);
            transform-origin: 0 0;
        }   

        img {
            display: block; 
        }

        .img-shadow {
            display: block;
            transform-origin: 0 50%;
                transform: perspective(850px) 
                    rotate3d(0, -1, 0, -15deg);

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                background: black;
                opacity: 0.55;
            }
        }
    }
//// overview: title
    h2 {    
        white-space: nowrap;
        font-family: $helvetica;
        font-weight: bold;        
        line-height: lineHeight(100, 120);
        color: white;
        a {color: white;}
        text-shadow: 0px 0px 2px #9d9d9d;
        padding: 2px;
        z-index: 1;
        opacity: 1;

        &.detail-title {
            opacity: 0;
        }
    }
//// overview: info texts
    .info {
        color: $text-color;
        line-height: lineHeight(14, 22);

        &.index {
            font-weight: 100;
        }

        &.more-info, 
        &.less-info {
            @include fontSize(12px);
            padding: 10px;
            white-space: nowrap;
            display: block;

            .icon {
                @include fontSize(17px);
                font-weight: 600;
            }
        }

        &.more-info {
            opacity: 1;
            visibility: visible;
        }

        &.less-info {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            background: rgba(0, 0, 0, 0.5);                     
        }

        &.project {
            line-height: lineHeight(14, 22);

            span {
                display: block;
                &.title {
                    text-decoration: underline;
                }
            }

            p {
                margin-bottom: 1rem;
            }

            li:before {
                content: "-";
                margin-right: 4px;
            }
        }

    }
//// overview: lines
    .lines {
        pointer-events: none;

        span.vertical {
            width: 0;
            border-left: 1px solid $line-color;              
        }

        span.horizontal {
            height: 0;
            border-top: 1px solid $line-color;
        }

    }

/// -------- detail ---------- ///

    article.detail {
        .overview-only {
            opacity: 0;
            visibility: hidden;
            display: none;
        }

        .detail-only {
            opacity: 1;
            visibility: visible;
            display: block;
        }
//// detail: overlay
        .overlay {
            background: black;
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            min-height: 100vh;
            max-height: 100vh;
            z-index: 5;
            overflow-y: scroll;
            > .container {
                height: auto;
                min-height: 100vh;
                max-width: 100vw;
            }
        }

        .first-row {
            @include clearfix;

            .col:first-child {
                padding-left: 0;
            }
        }

//// detail: key visual
        .key-visual {   
            .key-visual-img-container {
                transform: translate3d(0px, 0px, 0px);

                a {
                    pointer-events: none;
                }
            }
            .img-shadow {
                transform: perspective(850px) 
                        rotate3d(0, -1, 0, 0deg);

                &:after {
                    opacity: 0;
                }               
            }
        }

//// detail: title
        h2 {
            &.overview-title {
                pointer-events: none;
                opacity: 0;
            }
            &.detail-title {
                opacity: 1;
            }            
        }
//// detail: info texts
        .info {
            color: $text-color-light;

            &.less-info {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
            }

            &.more-info {
                opacity: 0;
                visibility: hidden;
            }

            &.project {
                overflow: visible;
            }

            &.project .project-detail {
                // background: rgba(0, 0, 0, .8);
                position: relative;
                .text {
                    position: relative;
                }
                
                .images {
                    position: relative;
                    margin: 10px 0 100px;
                    img {
                        display: block;
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }                   

                p {
                    font-family: $roboto;
                    font-weight: 400;
                    span.strong {
                        display: inline;
                        font-weight: 400;
                    }
                }
            }
        }
//// detail: lines
        .lines {
            span.horizontal.top, span.horizontal.bottom,
            span.vertical.left, span.vertical.right {
                border-color: $line-color-light;
            }    
        }

//// detail: even
        &:nth-child(even) {
            .key-visual {   
                .key-visual-img-container {
                    transform: translate3d(0px, 0px, 0px);

                    a {
                        pointer-events: none;
                    }
                }
                .img-shadow {
                    transform: perspective(850px) 
                            rotate3d(0, -1, 0, 0deg);

                    &:after {
                        opacity: 0;
                    }               
                }
            }
        }
    }



// ------------ big desktop ------------ //
    @media only screen and (min-width: 768px) {
    /// -------- <big desktop> overview ---------- ///
    //// <big desktop> overview: key visual
        .key-visual-container {
            width: 100vh; // margin top is calculated based on parent width instead of height
            max-width: $ideal-viewport-height * 1px;
            // height: 100%;
            min-width: $full-viewport-min-height;
            top: 0;
            left: calc((#{$line-vertical-margin} / #{$ideal-viewport-width}) * 100% + 4px);
            @media only screen and (min-width: $hd-desktop-min) {
                left: calc((#{$hd-line-vertical-margin * 2} / #{$ideal-viewport-width}) * 100% + 4px);
            }
        }

        .key-visual {   
            width: $key-visual-width;
            height: $key-visual-height;
            margin-top: calc((#{$line-vertical-margin} / #{$ideal-viewport-height}) * 100% + 4px); 

            @media only screen and (min-width: $hd-desktop-min) {
                width: $hd-key-visual-width;
                height: $hd-key-visual-height;
                margin-top: calc((#{$hd-line-vertical-margin} / #{$ideal-viewport-height}) * 100% + 4px); 
            }

            img {
                width: $key-visual-width-detail;
                height: $key-visual-height-detail;
                backface-visibility: hidden;
                @media only screen and (min-width: $hd-desktop-min) {
                    width: $hd-key-visual-width-detail;
                    height: $hd-key-visual-height-detail;
                }                
            }
            .img-shadow {
                width: $key-visual-width-detail;
                height: $key-visual-height-detail;
                @media only screen and (min-width: $hd-desktop-min) {
                     width: $hd-key-visual-width-detail;   
                     height: $hd-key-visual-height-detail;
                } 
                &:after {
                    width: $key-visual-width-detail;
                    height: $key-visual-height-detail;
                    @media only screen and (min-width: $hd-desktop-min) {
                        width: $hd-key-visual-width-detail;        
                        height: $hd-key-visual-height-detail;
                    } 
                }               
            }        
        }
    //// <big desktop> overview: title
        h2 {            
            @include fontSize(100px);            
            position: absolute;            

            &.overview-title {            
                left: percentage(360 / $ideal-viewport-width);
                top: percentage(360 / $ideal-viewport-height);
                transform: translateY(-50%);
            }

            &.detail-title {
                @include fontSize(60px); 
                left: calc(50% + #{($key-visual-width-detail / 2)} - 10px);

                @media only screen and (min-width: $hd-desktop-min) {
                    left: calc(50% + #{($hd-key-visual-width-detail / 2)} - 10px);
                }

                top: $detail-margin-top;
                transform: translate(-100%, -100%);
                transform-origin: 0 0;
            }
        }
    //// <big desktop> overview: info texts
        .info {
            @include fontSize(14px);
            position: absolute;

            &.time {
                @include fontSize(12px);
                left: calc((#{$line-vertical-margin} / #{$ideal-viewport-width}) * 100% + 14px);
                top: calc((#{$line-vertical-margin} / #{$ideal-viewport-height}) * 100% + -20px);

                @media only screen and (min-width: $hd-desktop-min) {
                    left: calc((#{$hd-line-vertical-margin * 2} / #{$ideal-viewport-width}) * 100% + 14px);
                    top: calc((#{$hd-line-vertical-margin} / #{$ideal-viewport-height}) * 100% + -20px);
                }
            }

            &.index {
                @include fontSize(18px);
                left: calc((#{$line-vertical-margin} / #{$ideal-viewport-width}) * 100% + #{$line-distance-horizontal} + 10px);
                top: calc((#{$line-vertical-margin} / #{$ideal-viewport-height}) * 100% - 30px);
                @media only screen and (min-width: $hd-desktop-min) {
                    left: calc((#{$hd-line-vertical-margin * 2} / #{$ideal-viewport-width}) * 100% + #{$hd-line-distance-horizontal} + 10px);
                    top: calc((#{$hd-line-vertical-margin} / #{$ideal-viewport-height}) * 100% - 30px);
                }
            }

            &.more-info {
                left: calc((#{$line-vertical-margin} / #{$ideal-viewport-width}) * 100% + #{$line-distance-horizontal});
                top: calc((#{$line-vertical-margin} / #{$ideal-viewport-height}) * 100% + #{$line-distance-vertical} - 34px);
                @media only screen and (min-width: $hd-desktop-min) {
                    left: calc((#{$hd-line-vertical-margin * 2} / #{$ideal-viewport-width}) * 100% + #{$hd-line-distance-horizontal});
                    top: calc((#{$hd-line-vertical-margin} / #{$ideal-viewport-height}) * 100% + #{$hd-line-distance-vertical} - 34px);
                }
            }

            &.less-info {
                position: fixed;                
                right: 30px;
                bottom: 30px;
            }


            &.project {

                left: ($line-vertical-margin / $ideal-viewport-width) * 100%;
                top: calc((#{$line-vertical-margin} / #{$ideal-viewport-height}) * 100% + #{$line-distance-vertical} + 14px);

                width: $line-distance-horizontal;

                @media only screen and (min-width:$hd-desktop-min) {
                    left: ($hd-line-vertical-margin * 2 / $ideal-viewport-width) * 100%;
                    top: calc((#{$hd-line-vertical-margin} / #{$ideal-viewport-height}) * 100% + #{$hd-line-distance-vertical} + 14px);

                    width: $hd-line-distance-horizontal;
                }

                span {
                    &.title {
                        @include fontSize(12px);
                    }
                }                
            }
        }


    //// <big desktop> overview: lines
        .lines {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            max-height: $ideal-viewport-height * 1px;

            @media only screen and (min-width: $hd-desktop-min) {
                min-height: $hd-full-viewport-min-height;
            }

            @media only screen and (min-width: 768px) {
                min-height: $full-viewport-min-height;
            }

            @media only screen and (max-width: 767px) {
                min-height: $full-viewport-min-height-mobile;
            }

            span {
                display: block;
                position: absolute;
            }

            span.vertical {
                // height: (760 / $ideal-viewport-height) * 100%;
            }

            span.horizontal {
                width: (1208 / $ideal-viewport-width) * 100%;

                &.top {
                    top: ($line-vertical-margin / $ideal-viewport-height) * 100%;
                    left: 0;
                    width: calc(#{$line-vertical-margin} / #{$ideal-viewport-width} * 100% + #{$line-distance-horizontal} + (140 / #{$ideal-viewport-width} * 100%));
                }

                &.bottom {
                    top: calc((#{$line-vertical-margin} / #{$ideal-viewport-height}) * 100% + #{$line-distance-vertical});
                    right: 0;
                    width: calc(100vw - (#{$line-vertical-margin} / #{$ideal-viewport-width}) * 100% + 77px);
                }

                @media only screen and (min-width: $hd-desktop-min) {
                    &.top {
                        top: ($hd-line-vertical-margin / $ideal-viewport-height) * 100%;
                        left: 0;
                        width: calc(#{$hd-line-vertical-margin} / #{$ideal-viewport-width} * 100% + #{$hd-line-distance-horizontal} + (140 / #{$ideal-viewport-width} * 100%));
                    }

                    &.bottom {
                        top: calc((#{$hd-line-vertical-margin} / #{$ideal-viewport-height}) * 100% + #{$hd-line-distance-vertical});
                        right: 0;
                        width: calc(100vw - (#{$hd-line-vertical-margin} / #{$ideal-viewport-width}) * 100% + 77px);
                    }
                }
            }
        } 

    //// <big desktop> overview: odd even
        article:nth-child(odd) .lines {
            span.vertical {            
                &.left {
                    left: calc((#{$line-vertical-margin} / #{$ideal-viewport-width}) * 100%);
                    top: 0;
                    bottom: calc((#{$line-vertical-margin} / #{$ideal-viewport-height}) * 100%);
                }
                &.right {
                    left: calc((#{$line-vertical-margin} / #{$ideal-viewport-width}) * 100% + #{$line-distance-horizontal});
                    top: calc((#{$line-vertical-margin} / #{$ideal-viewport-height}) * 100% - 45px);
                    bottom: 0;
                }

                @media only screen and (min-width: $hd-desktop-min) {
                    &.left {
                        left: calc((#{$hd-line-vertical-margin} * 2 / #{$ideal-viewport-width}) * 100%);
                        top: 0;
                        bottom: calc((#{$hd-line-vertical-margin} / #{$ideal-viewport-height}) * 100%);
                    }
                    &.right {
                        left: calc((#{$hd-line-vertical-margin} * 2 / #{$ideal-viewport-width}) * 100% + #{$hd-line-distance-horizontal});
                        top: calc((#{$hd-line-vertical-margin} / #{$ideal-viewport-height}) * 100% - 45px);
                        bottom: 0;
                    }
                }
            }            
        }

        article:nth-child(even) {
            span.vertical {            
                &.left {
                    left: auto;
                    right: calc((#{$line-vertical-margin} / #{$ideal-viewport-width}) * 100% + #{$line-distance-horizontal});
                    top: calc((#{$line-vertical-margin} / #{$ideal-viewport-height}) * 100% - 45px);
                    bottom: 0;
                }
                &.right {
                    left: auto;
                    right: calc((#{$line-vertical-margin} / #{$ideal-viewport-width}) * 100% );
                    top: 0;
                    bottom: calc((#{$line-vertical-margin} / #{$ideal-viewport-height}) * 100%);
                }

                @media only screen and (min-width: $hd-desktop-min) {
                    &.left {
                        left: auto;
                        right: calc((#{$hd-line-vertical-margin * 2} / #{$ideal-viewport-width}) * 100% + #{$hd-line-distance-horizontal});
                        top: calc((#{$hd-line-vertical-margin} / #{$ideal-viewport-height}) * 100% - 45px);
                        bottom: 0;
                    }
                    &.right {
                        left: auto;
                        right: calc((#{$hd-line-vertical-margin * 2} / #{$ideal-viewport-width}) * 100% );
                        top: 0;
                        bottom: calc((#{$hd-line-vertical-margin} / #{$ideal-viewport-height}) * 100%);
                    }

                }
            }

            span.horizontal {
                &.top {
                    left: auto;
                    right: 0;
                }
                &.bottom {
                    left: 0;
                    right: auto;
                }

            }

            .key-visual-container {
                position: absolute;
                left: auto;
                right: calc((#{$line-vertical-margin} / #{$ideal-viewport-width}) * 100% + 4px);

                @media only screen and (min-width: $hd-desktop-min) {
                    right: calc((#{$hd-line-vertical-margin * 2} / #{$ideal-viewport-width}) * 100% + 4px);
                }
            }

            .key-visual {
                position: absolute;
                right: 0;

                .key-visual-img-container {
                    height: $key-visual-height-detail;
                    @media only screen and (min-width: $hd-desktop-min) {
                        height: $hd-key-visual-height-detail
                    }
                    transform: perspective(850px) 
                        translate3d(0px, -15px, -50px);
                    transform-origin: 100% 0;
                }
                        
                img {
                    position: absolute;
                    right: 0;
                    display: block;       
                }

                .img-shadow {
                    position: absolute;
                    right: 0;
                    transform-origin: 100% 50%;
                    transform: perspective(850px) 
                        rotate3d(0, -1, 0, 15deg);

                    &:after {
                        left: auto;
                        right: 0;
                    }
                }

            }
            
            h2.overview-title {
                left: ($line-vertical-margin / $ideal-viewport-width) * 100%;
                @media only screen and (min-width: $hd-desktop-min) {
                    left: ($hd-line-vertical-margin * 2 / $ideal-viewport-width) * 100%;
                }
            }

            .info {

                &.time {
                    left: auto;
                    right: calc((#{$line-vertical-margin} / #{$ideal-viewport-width}) * 100% + #{$line-distance-horizontal} - 70px);
                }

                &.index {
                    left: auto;
                    right: calc((#{$line-vertical-margin} / #{$ideal-viewport-width}) * 100% - 36px);
                }

                &.more-info {
                    left: auto;
                    right: calc((#{$line-vertical-margin} / #{$ideal-viewport-width}) * 100% - 92px);
                }

                &.project {
                    left: auto;
                    right: ($line-vertical-margin / $ideal-viewport-width) * 100%;
                }

                @media only screen and (min-width: $hd-desktop-min) {
                    &.time {
                        left: auto;
                        right: calc((#{$hd-line-vertical-margin * 2} / #{$ideal-viewport-width}) * 100% + #{$hd-line-distance-horizontal} - 70px);
                    }

                    &.index {
                        left: auto;
                        right: calc((#{$hd-line-vertical-margin * 2} / #{$ideal-viewport-width}) * 100% - 36px);
                    }

                    &.more-info {
                        left: auto;
                        right: calc((#{$hd-line-vertical-margin * 2} / #{$ideal-viewport-width}) * 100% - 92px);
                    }

                    &.project {
                        left: auto;
                        right: ($hd-line-vertical-margin * 2 / $ideal-viewport-width) * 100%;
                    }
                }
            }
        }

    /// -------- <big desktop> detail ---------- ///
        article.detail {
            // * { transition: all 0.05s ease; }

            .first-row {
                margin-bottom: 30px;
            }

            .detail-short-row {
                width: 78%;
            }
        
    //// <big desktop> detail: key visual
            .key-visual-container {
                left: calc(50% - #{($key-visual-width-detail / 2)});
                @media only screen and (min-width: $hd-desktop-min){
                    left: calc(50% - #{($hd-key-visual-width-detail / 2)});
                }                
                position: relative;
            }
            .key-visual {
                position: relative;
                width: $key-visual-width-detail;
                @media only screen and (min-width: $hd-desktop-min){
                    width: $hd-key-visual-width-detail;
                }
                height: auto;            
                margin-top: $detail-margin-top;
            }
    //// <big desktop> detail: info texts
            .info {

                &.time {
                    left: calc(50% - #{$key-visual-width-detail / 2 - 20});

                    @media only screen and (min-width: $hd-desktop-min) {
                        left: calc(50% - #{$hd-key-visual-width-detail / 2 - 20});
                    }

                    top: 156px;
                }

                &.index {
                    min-width: 30px;
                    left: calc(50% + #{$key-visual-width-detail / 2 + 15});

                    @media only screen and (min-width: $hd-desktop-min) {
                        left: calc(50% + #{$hd-key-visual-width-detail / 2 + 15});
                    }

                    top: calc(#{$detail-margin-top} - 35px);
                }
                

                &.project {
                    position: relative;
                    width: $key-visual-width-detail;
                    left: calc(50% - #{($key-visual-width-detail / 2)});

                    @media only screen and (min-width: $hd-desktop-min){
                        width: $hd-key-visual-width-detail;
                        left: calc(50% - #{($hd-key-visual-width-detail / 2)});
                    }

                    // padding-top: 40px;                    
                }

                &.project .project-detail {                   
                    width: $key-visual-width-detail;

                    @media only screen and (min-width: $hd-desktop-min){
                        width: $hd-key-visual-width-detail;
                    }

                    margin: 0px auto;

                    .text {
                        padding: 30px 14px; 
                    }

                    p {
                        width: 70%;
                    }
                }
            }
    //// <big desktop> detail: lines
            .lines {
                height: 100%;
                span.horizontal {
                    width: 100%;

                    &.top {
                        top: $detail-margin-top;
                        
                    }

                    &.bottom {
                        top: ($detail-margin-top + $key-visual-height-detail);
                        @media only screen and (min-width: $hd-desktop-min) {
                            top: ($detail-margin-top + $hd-key-visual-height-detail);
                        }
                    }
                }
                span.vertical {
                    position: fixed;
                    &.left {
                        top: 0;
                        bottom: 0;
                        left: calc(50% - #{($key-visual-width-detail / 2)});
                        @media only screen and (min-width: $hd-desktop-min) {
                            left: calc(50% - #{($hd-key-visual-width-detail / 2)});
                        }
                    }

                    &.right {
                        top: 0;
                        bottom: 0;
                        left: calc(50% + #{($key-visual-width-detail / 2)});
                        @media only screen and (min-width: $hd-desktop-min) {
                            left: calc(50% + #{($hd-key-visual-width-detail / 2)});
                        }
                    }
                }
            }

    //// <big desktop> detail: odd even

            &:nth-child(even) {
                span.vertical {            
                    &.left {
                        top: 0;
                        bottom: 0;
                        left: auto;
                        right: calc(50% + #{($key-visual-width-detail / 2)});
                        @media only screen and (min-width: $hd-desktop-min) {
                            right: calc(50% + #{($hd-key-visual-width-detail / 2)});
                        }
                    }
                    &.right {
                        top: 0;
                        bottom: 0;
                        left: auto;
                        right: calc(50% - #{($key-visual-width-detail / 2)});
                        @media only screen and (min-width: $hd-desktop-min) {
                            right: calc(50% - #{($hd-key-visual-width-detail / 2)});
                        }
                    }
                }

                .key-visual-container {
                    left: auto;
                    right: calc(50% - #{($key-visual-width-detail / 2)});
                    @media only screen and (min-width: $hd-desktop-min) {
                        right: calc(50% - #{($hd-key-visual-width-detail / 2)});
                    }
                    position: absolute;
                }

                .key-visual {
                    right: 0;
                    position: absolute;
                    img {
                        margin-left: 0;
                    }
                }

                .info{
                    &.project {
                        position: absolute;

                        top: calc(#{$detail-margin-top} + #{$key-visual-height-detail});

                        @media only screen and (min-width: $hd-desktop-min) {
                            top: calc(#{$detail-margin-top} + #{$hd-key-visual-height-detail});
                        }
                        @media only screen and (min-width: 768px) and (max-width: 1023px) {
                            top: calc(#{$detail-margin-top} + #{$tablet-key-visual-height-detail});
                        }
                    }
                }
            }
        }






    }
// ------------ smaller desktop and tablet landscape------------ //
    @media only screen and (min-width: 1024px) and (max-width: 1160px) {
        h2.overview-title {
            @include fontSize(84px);
        }
    }
// ------------ tablet portrait --------------- //
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        h2.overview-title {
            white-space: normal;
            @include fontSize(74px);;
        } 

        h2.detail-title {
            @include fontSize(44px); 
            left: calc(50% + #{($tablet-key-visual-width-detail / 2)} - 10px);
            top: $detail-margin-top;
        }

        .key-visual-container {
            left: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-width}) * 100% + 4px);
        }

        .key-visual {   
            width: $tablet-key-visual-width;
            height: $tablet-key-visual-height;
            margin-top: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-height}) * 100% + 4px); 
            img {
                width: $tablet-key-visual-width-detail;
                height: $tablet-key-visual-height-detail;        
            }
            .img-shadow {
                width: $tablet-key-visual-width-detail;
                height: $tablet-key-visual-height-detail;
                &:after {
                    width: $tablet-key-visual-width-detail;
                    height: $tablet-key-visual-height-detail;
                }               
            }        
        }

        .info {

            &.time {
                left: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-width}) * 100% + 14px);
                top: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-height}) * 100% + -20px);
            }

            &.index {
                @include fontSize(18px);
                left: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-width}) * 100% + #{$tablet-line-distance-horizontal} + 10px);
                top: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-height}) * 100% - 30px);
            }

            &.more-info {
                background: rgba(white, 0.5);
                left: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-width}) * 100% + #{$tablet-line-distance-horizontal} - 94px);
                top: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-height}) * 100% + #{$tablet-line-distance-vertical} - 43px);
                border: 1px solid rgba(26, 26, 26, 0.05);
                border-right: 0 transparent;
                border-bottom: 0 transparent;
            }

            &.project {
                left: ($tablet-line-vertical-margin / $ideal-viewport-width) * 100%;
                top: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-height}) * 100% + #{$tablet-line-distance-vertical} + 14px);
                width: $tablet-line-distance-horizontal;
            }
        }  

        .lines {
            span.horizontal {
                &.top {
                    top: ($tablet-line-vertical-margin / $ideal-viewport-height) * 100%;
                    width: calc(#{$tablet-line-vertical-margin} / #{$ideal-viewport-width} * 100% + #{$tablet-line-distance-horizontal} + (140 / #{$ideal-viewport-width} * 100%));
                }

                &.bottom {
                    top: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-height}) * 100% + #{$tablet-line-distance-vertical});                    
                    width: calc(100vw - (#{$tablet-line-vertical-margin} / #{$ideal-viewport-width}) * 100% + 77px);
                }
            }
        }

        article:nth-child(odd) .lines {
            span.vertical {            
                &.left {
                    left: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-width}) * 100%);
                    top: 0;
                    bottom: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-height}) * 100%);
                }
                &.right {
                    left: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-width}) * 100% + #{$tablet-line-distance-horizontal});
                    top: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-height}) * 100% - 45px);
                    bottom: 0;
                }
            }          
        }    
    
        article:nth-child(even) {
            .key-visual-container {
                right: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-width}) * 100% + 4px);
            }

            .key-visual {
                .key-visual-img-container {
                    height: $tablet-key-visual-height-detail;
                    transform: perspective(850px) 
                        translate3d(0px, -15px, -50px);
                    transform-origin: 100% 0;
                }
            }
        
            h2.overview-title {
                left: ($tablet-line-vertical-margin / $ideal-viewport-width) * 100%;
            }

            span.vertical {            
                &.left {
                    top: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-height}) * 100% - 45px);
                    right: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-width}) * 100% + #{$tablet-line-distance-horizontal});
                }
            
                &.right {
                    left: auto;
                    right: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-width}) * 100% );
                    bottom: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-height}) * 100%);
                }
            }

            .info {

                &.time {
                    right: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-width}) * 100% + #{$tablet-line-distance-horizontal} - 70px);
                }

                &.index {
                    right: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-width}) * 100% - 36px);
                }

                &.more-info {
                    right: calc((#{$tablet-line-vertical-margin} / #{$ideal-viewport-width}) * 100% + 1px);
                }

                &.project {
                    right: ($tablet-line-vertical-margin / $ideal-viewport-width) * 100%;
                }
            }
        }
        
        article.detail {   
            .key-visual-container {
                left: calc(50% - #{($tablet-key-visual-width-detail / 2)});
            }

            &:nth-child(even) .key-visual-container {
                left: auto;
                right: calc(50% - #{($tablet-key-visual-width-detail / 2)});
            }

            .key-visual {
                width: $tablet-key-visual-width-detail;
                margin-top: $detail-margin-top;
            }

            .info {

                &.time {
                    left: calc(50% - #{($tablet-key-visual-width-detail / 2)} + 10px);
                    top: 156px;
                }

                &.index {
                    left: calc(50% + #{($tablet-key-visual-width-detail / 2)} + 10px);
                    top: 100px;
                }
                

                &.project {
                    position: relative;
                    width: $tablet-key-visual-width-detail;
                    left: calc(50% - #{($tablet-key-visual-width-detail / 2)});
                    // padding-top: 40px;                    
                }

                &.project .project-detail {                   
                    width: $tablet-key-visual-width-detail;
                    margin: 0px auto;

                    .text {
                        padding: 30px 14px; 
                    }

                    p {
                        width: 70%;
                    }
                }
            }

            .lines {
                
                span.horizontal {
                    width: 100%;

                    &.top {
                        top: $detail-margin-top;
                        
                    }

                    &.bottom {
                        top: calc(#{$detail-margin-top} + #{$tablet-key-visual-height-detail});
                    }
                }

                span.vertical {
                    height: 100%;

                    &.left {
                        top: 0;
                        bottom: 0;
                        left: calc(50% - #{($tablet-key-visual-width-detail / 2)});
                    }

                    &.right {
                        top: 0;
                        bottom: 0;
                        left: calc(50% + #{($tablet-key-visual-width-detail / 2)});
                    }
                }
            }
        }

        
    }

// ------------ mobile -------------//
    @media only screen and (max-width: 767px) {
        article, article:nth-child(odd), article:nth-child(even) {
            .overview-only-mobile {
                display: block;
            }

            h2 {
                white-space: normal;
                position: absolute;  
                overflow: visible;

                &.overview-title {                                      
                    @include fontSize(68px);
                    line-height: 1;
                    text-align: right;
                    top: 50vmin;
                    transform: translateY(-60%);
                    right: 25px;
                    
                    transition: all 0.2s ease-in;
                }

                &.detail-title {
                    @include fontSize(36px);
                
                    position: relative;
                    visibility: hidden;
                    // display: none;
                    opacity: 0;
                    height: 0;
                    padding: 0;
                    
                    transition: opacity 0.4s ease-in 0.2s;
                }

            }

            .lines {
                display: none;
            }

            .key-visual-container, .key-visual-container * {
                transition: all 0.4s ease-in;
            }

            .key-visual {
                .key-visual-img-container {
                    transform: none;

                    .img-shadow {
                        width: 100vw;
                        height: $key-visual-height-detail;
                        transform: none;

                        &:after {
                            opacity: 0.45;
                            width: 100%;
                            height: 100%;
                            transition: opacity 0.4s ease-in;
                        }

                        img {
                            width: $key-visual-width-detail;
                            height: $key-visual-height-detail;
                            transition: all 0.4s ease-in;
                        }

                    }
                }
            }

            .info {
                @include fontSize(14px);
                // color: black;
                &.time {
                    display: none;
                }

                &.index {
                    padding: 5px 10px;
                    @include fontSize(20px);

                    position: absolute;
                    top: 0px;
                    left: 0px;

                    color: rgba(255, 255, 255, .6);
                    border: 1px solid rgba(255, 255, 255, .15);
                    background: rgba(0, 0, 0, .35);                    
                }

                &.project {
                    &, & * {
                        transition: 0s;
                    }
                    @include fontSize(15px);
                    padding: 40px 20px 0px 20px;
                    .short-row {
                        width: 84%;
                    }
                    line-height: 1.8;

                    .col {
                        span {
                            margin-bottom: 1em;
                        }
                    }
                    .col.col-1-3 {
                        display: none;
                    }
                    
                    .col, .text {
                        span.title {
                            font-family: $roboto;
                            @include fontSize(16px);
                            text-decoration: none;
                            text-transform: uppercase;
                            // color: black;

                            display: inline-block;
                            letter-spacing: 0.15em;
                            line-height: 1.5;

                            padding: 0 12px 0px 4px;                            
                            border-bottom: 1px solid #000000;
                            margin-bottom: 10px;
                        }

                        span.content {
                            display: block;
                        }
                    }
                }

                &.project .project-detail {
                    overflow: visible;
                    .text{
                        margin-top: 2rem;
                        margin-bottom: 1rem;
                    }
                    
                    span {
                        display: inline;
                    }

                    span.content {
                        display: block;
                    }

                    p {
                        font-family: $raleway;
                        line-height: 2;
                        margin-bottom: 1rem;
                    }

                    .images {
                        margin-left: -10px;
                        margin-right: -10px;
                        margin-bottom: 40px;
                        img {
                            display: block;
                            width: 100%;
                            margin-bottom: 20px;
                            
                        }
                    }
                }

                &.more-info {
                    @include fontSize(16px);

                    position: absolute;
                    top: $key-visual-height-detail;
                    right: 0px;
                    transform: translateY(-100%);  
                    padding: 5px 15px;                   

                    color: rgba(255, 255, 255, .9);                    
                    // background: transparentize($theme-color-adam-configurator, .25);                    
                    border: 1px solid rgba(255, 255, 255, .15);
                }

                &.less-info {
                    position: fixed;
                    right: -1px;
                    top: 70%;
                    border: 1px solid rgba(255, 255, 255, 0.15);
                    border-right: 0 transparent;
                    background: rgba(0, 0, 0, 0.5);
                }
            }

        }

        article.detail {    
            .overview-only-mobile {
                display: none;
            }        
            .overlay {
                background: black;
                // position: relative;;
                // height: auto;
                -webkit-overflow-scrolling: touch;

                > .container {
                    height: auto;
                    max-width: 100vw;
                }
            }

            .key-visual {
                .key-visual-img-container {
                    .img-shadow {
                        &, &:after {
                            height: $key-visual-height / $key-visual-width * 100vw;
                        } 

                        &:after {
                            opacity: 0;
                            width: 100vw;
                        }

                        img {
                            width: 100vw;
                            height: $key-visual-height / $key-visual-width * 100vw;
                        }
                    }

                }
            }

            h2.detail-title {                                    
                visibility: visible;
                // display: block;
                opacity: 1;
                height: auto;
                margin: 20px; 
                // top: calc(#{$key-visual-height / $key-visual-width * 100vw} + 20px);
            }

            .info {
                color: white;
                &.project, 
                &.project .project-detail {
                    .col, .text {
                        span.title {
                            @include fontSize(15px);
                            border-color: #ffffff;
                            margin-bottom: 0;
                        }

                        &.col-2-3 {
                            display: none;
                        }
                    }
                }

                &.project {
                    padding-top: 0px; 
                    .col.col-1-3 {
                        display: block;
                    }
                    p {
                        margin-bottom: 0;
                    }
                }
                &.project-detail{
                    margin-top: 1rem;
                    overflow: visible;
                }
            }
        }
    }
}