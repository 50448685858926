/**
 * component: about
 */

#about {
    height: auto;

    &, & * {
        overflow: visible;
    }
    .container {
        padding: 60px 20px 150px;

        &:before, 
        &:after {
            content: "";
            display: block;
            width: 50px;
            height: 80px;
            border: 10px solid black;
            position: absolute;
        }

        &:before {
            border-right: none;
            border-bottom: none;
            top: 35px;
        }

        &::after {
            border-left: none;
            border-top: none;
            right: 20px;
            bottom: 100px;
        }
    }

    .picture-container {
        text-align: center;
        margin-bottom: 40px;
    }

    #profile-photo {
        width: 100%;
        max-width: 300px;
    }

    .text {
        font-family: $raleway;
        line-height: (26px / 16px);
        p {
            margin-bottom: 1em;

            &::last-child {
                margin-bottom: 2em;
            }
        }
        .emphasis {
            font-family: arial;
            font-weight: bold;
        }
    }

    .col:first-child {
        margin-bottom: 50px;
        &:after {
            content: "";
            display: block;
            width: 70px;
            height: 70px;
            background: black;
            position: relative;
            float: right;
            top: -72px;
        }
    }

    .text.little-facts {
        @include fontSize(14px);
        margin-right: 75px;
    }

    .links {
        margin: 50px 80px 0 0;

        ul {
            text-align: right;
        }

        li {
            line-height: (26px / 16px);
        }
        a, a:hover, a:active, a:visited {
            font-size: 14px;
            color: black;
            border-bottom: 1px solid black;
        }
    }

    @media only screen and (min-width: 768px) {
        min-height: $full-viewport-min-height;
        max-height: $ideal-viewport-height * 1px;

        .col {
            float: none;
        }

        .container {
            height: auto;
            padding-top: 80px;
        }

        .picture-container {
            text-align: left;
            padding-left: 20px;
        }
    }

    @media only screen and (min-width: 1024px) {
        min-height: $hd-full-viewport-min-height;

        .container {
            padding: 120px 40px 0;

            &:before {
                top: 90px;
            }

            &:after {
                right: 40px;
            }
        }

        .col {
            float: left;

            &.col-left {
                width: percentage(850 / 1330);
            }

            &.col-right {
                float:right;
                width: percentage(430 / 1330);
            }
        }

        .picture-container {
            float: left;
            margin: 0 20px 0 0;
            width: percentage(300 / 835);
            min-width: 240px;
        }

        .col-left .text {
            margin-top: 100px;
        }

        .col-right .text {
            margin-top: 200px;
        }

        .links {
            margin: 150px 100px 0 0;

            li {
                display: inline-block;
                margin-left: 50px;
            }
        }

        .col.col-left:after {
            width: 50px;
            height: 50px;
            top: -50px;
        }
    }

    @media only screen and (min-width: 1281px) {
        max-height: ($ideal-viewport-height + 200) * 1px;
        min-height: $ideal-viewport-height * 1px;

        .picture-container {
            margin-right: 40px;
        }

        .col-left .text {
            width: percentage(465 / 835);
            float: left;
        }

        .col.col-left:after {
            width: 80px;
            height: 80px;
            top: -60px;
        }

        .links {
            margin: 550px 140px 0 0;

            li {
                margin-left: 60px;
            }
        }
    }
}

/** End of component: about */