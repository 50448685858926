
/* Function and mixins -----*/
// clearfix
@mixin clearfix {
    &:after {
            content: "";
            display: table;
            clear: both;
        }
}

// font size and line height calculations
@function calculateRem($size) {
    $remSize: $size / 16px;
    @return #{$remSize}rem;
}

@mixin fontSize($size) {
    font-size: $size; //Fallback in px
    font-size: calculateRem($size);
}

@function lineHeight($pixelFontSize, $pixelLineHeight) {
    @return $pixelLineHeight / $pixelFontSize;
}

@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

/* Normalize ----------------*/
body {
    -webkit-text-size-adjust: 100% // Prevent font scaling in iOS landscape
}

a {
    text-decoration: none;
}

* {
    overflow-x: visible !important;
}

/* breakpoint */
$hd-desktop-min: 1330px;
$desktop-min: 1024px;
$tablet-min: 768px;
$mobile-max: 767px;

/* length variables ---------*/
$ideal-viewport-width: 1440;
$ideal-viewport-height: 900;
$col-padding: 14px;
$full-viewport-min-height: 768px;
$hd-full-viewport-min-height: 832px;
$full-viewport-min-height-mobile: 667px;
$mobile-ui-bar-height: 67px;

/* Colors -------------------*/
$text-color: rgba(0, 0, 0, 0.7);
$text-color-light: #b3b3b3;
// $line-color: #b3b3b3;
$line-color: rgba(26, 26, 26, .05);
$line-color-light:rgba(255, 255, 255,0.1);


$theme-color-opel-relaunch: #88c6f9;
// $theme-color-ard: #ff97ba; 
$theme-color-ard: #e0386e; 
$theme-color-adam-configurator: #ffea4d;



/* Fonts --------------------*/
$raleway: "raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
$roboto: "Roboto", Arial, sans-serif;
$helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif;
html {
    font-family: $raleway;
}



/* Typography ---------------*/


/* Layout -------------------*/


* {
    overflow: auto; //don't collapse margin so marign-top on child doesn't move parent
    transition: all 200ms ease; //smooth everything
}

html.no-scroll {
    overflow: hidden;

    .overlay > .container {
        height: 100vh !important;
    }

    #projects article:nth-child(odd) .info.project {
        top: auto !important;
    }
}

@media only screen and (min-width: 768px) {

    html.no-scroll {
        overflow: hidden;
    }

    .row {
        @include clearfix();
        box-sizing: border-box;
        padding: 0 $col-padding / 2;
    }

    .col {
        float: left;
        box-sizing: border-box;
        padding: 0 $col-padding / 2;
    }

    .col-1-2 {
        width: 50%;
    }

    .col-1-3 {
        width: 1 / 3 * 100%;
    }

    .col-2-3 {
        width: 2 / 3 * 100%;
    }
}



.container {
    position: relative;
    max-width: #{$ideal-viewport-width}px;
    margin: 0 auto;
}

.boundary {
    // box-shadow: 0 0 10px -1px #ddd;
    –webkit-overflow-scrolling: touch; // for older iOS
    overflow: hidden;
}

.full-viewport {
    width: 100%;
    height: 100vh;
    
    overflow: hidden;

    > .container {
        overflow: auto;
        height: 100vh;     
        max-height: $ideal-viewport-height * 1px;   
    }

    @media only screen and (min-width: 768px) {
        min-height: $full-viewport-min-height;
        max-height: $ideal-viewport-height * 1px;
         > .container {
            min-height: $full-viewport-min-height;    
            max-height: $ideal-viewport-height * 1px; 
        }
    }

    @media only screen and (min-width: $hd-desktop-min) {
        min-height: $hd-full-viewport-min-height;
         > .container {
            min-height: $hd-full-viewport-min-height;     
        }
    }

    // mobile
    @media only screen and (max-width: 767px) {
        height: auto;
        min-height: $full-viewport-min-height-mobile - $mobile-ui-bar-height;
        > .container {
            height: auto;
            min-height: $full-viewport-min-height-mobile - $mobile-ui-bar-height
        }
    }

    // mobile landscape
    @media only screen and (max-width: 767px) and (orientation: landscape) { 
        min-height: $full-viewport-min-height-mobile;
        > .container {
            min-height: $full-viewport-min-height-mobile;
        }
    }
}